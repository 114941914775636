import classNames from "classnames"
import type { ReactNode } from "react"
import { Link } from "react-router-dom"

export interface Tab {
  name: string
  renderContent: () => ReactNode
}

export default function Tabs(props: { activeTab: string; tabs: string[] }) {
  const { activeTab, tabs } = props

  return (
    <div className="sticky top-[390px]">
      <div className="border-b border-gray-200">
        <nav aria-label="Tabs" className="-mb-px flex space-x-8">
          {tabs.map(tab => (
            <Link
              key={tab}
              to={`?tab=${tab}`}
              aria-current={tab === activeTab ? "page" : undefined}
              className={classNames(
                tab === activeTab
                  ? "border-indigo-500 text-indigo-600"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                "whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
              )}
            >
              {tab}
            </Link>
          ))}
        </nav>
      </div>
    </div>
  )
}
