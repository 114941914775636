import LoadingScreen from "@/pages/loading-screen"
import Routes from "@/routes"
import { useAuth } from "@/services/auth-service"
import { useEffect } from "react"

export default function App() {
  const auth = useAuth()
  const path = window.location.pathname
  const isAuthRequired = path !== "/login" && !path.startsWith("/expense/")
  const isNotAuthorized = isAuthRequired && !auth.user

  useEffect(() => {
    if (!auth.isLoading && isNotAuthorized) {
      window.location.href = "/login"
    }
  }, [auth.isLoading, isNotAuthorized])

  return (
    <>
      <LoadingScreen />

      {auth.isLoading || isNotAuthorized ? null : <Routes />}
    </>
  )
}
