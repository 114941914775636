import { AvatarContextProvider } from "@/components/avatar"
import PageHeader from "@/components/page-header"
import ShareButton from "@/components/share-button.tsx"
import Balances from "@/pages/expense-page/balances.tsx"
import { ReceiptItem } from "@/pages/expense-page/receipt-item"
import UserManagementScreen from "@/pages/expense-page/user-management-screen.tsx"
import { UserSelectionBar } from "@/pages/expense-page/user-selection-bar"
import { useAuth } from "@/services/auth-service"
import { updateExpense, updateExpenseItem, useExpenseData } from "@/services/expense-service"
import type { Expense, ExpenseItem, UserProfile } from "@/types"
import { roundToPlaces } from "@/utils/number-utils.ts"
import { toCurrency } from "@/utils/string-utils"
import classNames from "classnames"
import { type Variants, motion } from "framer-motion"
import { DateTime } from "luxon"
import { type ComponentProps, type ReactNode, useEffect, useState } from "react"
import { useParams } from "react-router-dom"

const listAnimationVariants: Variants = {
  initial: {
    opacity: 0,
    x: -15
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: { staggerChildren: 0.05 }
  }
}

export type SelectedUser = UserProfile | "everyone"

export default function ExpensePage() {
  const { id } = useParams()
  const { expense, items, members } = useExpenseData(id ?? null)
  const [selectedUser, setSelectedUser] = useState<SelectedUser | null>(null)
  const [isUserManagementScreenOpen, setIsUserManagementScreenOpen] = useState(false)
  const unclaimedCount = items?.filter(item => !item.userIds?.length).length ?? 0
  const isLoggedIn = !!useAuth().user

  useEffect(() => {
    if (selectedUser === "everyone") return
    if (!selectedUser || !members?.some(m => m.id === selectedUser.id)) {
      setSelectedUser(members?.[0] ?? null)
    }
  }, [selectedUser, members])

  if (!expense) return null

  const handleItemClick = async (item: ExpenseItem) => {
    if (!selectedUser) return
    if (selectedUser === "everyone") {
      const isRemovingEveryone = item.userIds?.length === members?.length
      const userIds = isRemovingEveryone ? [] : members?.map(u => u.id) ?? []
      await updateExpenseItem(expense.id, item.id, { userIds })
    } else {
      const isRemovingMember = item.userIds?.includes(selectedUser.id)
      const userIds = isRemovingMember
        ? item.userIds?.filter(id => id !== selectedUser.id)
        : [...(item.userIds ?? []), selectedUser.id].sort()

      await updateExpenseItem(expense.id, item.id, { userIds })
    }
  }

  const dateLabel = !expense.date
    ? null
    : DateTime.fromJSDate(expense.date?.toDate()).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)

  return (
    <AvatarContextProvider users={members ?? []}>
      <div className="bg-gradient-to-r from-st-green to-st-blue min-h-full font-abel">
        <div className="flex flex-col relative z-0">
          <PageHeader
            name={expense.name ?? ""}
            backButton={isLoggedIn}
            subHeader={
              <div className="pb-4">
                {dateLabel} • {items?.length ?? 0} items
                {unclaimedCount > 0 && (
                  <>
                    {" • "}
                    <span className="text-red-600">{unclaimedCount} unclaimed</span>
                  </>
                )}
              </div>
            }
            trailingButtons={[
              <ShareButton
                url={window.location.href}
                title={`${expense?.name} | SnapTab`}
                key="share"
              />
            ]}
          />

          <div>
            <ExpensePageHeader expense={expense} members={members} items={items} />

            <motion.div
              className="bg-white grow pb-20"
              variants={listAnimationVariants}
              initial="initial"
              animate="animate"
            >
              {items?.map(item => (
                <ReceiptItem
                  item={item}
                  key={item.ref.id}
                  users={members ?? null}
                  variants={listAnimationVariants}
                  onClick={() => handleItemClick(item)}
                />
              ))}
            </motion.div>
          </div>

          <UserSelectionBar
            users={members ?? []}
            selectedUser={selectedUser}
            onChange={setSelectedUser}
            onManageUsersClick={() => setIsUserManagementScreenOpen(true)}
          />
        </div>

        <UserManagementScreen
          expense={expense}
          items={items ?? []}
          isOpen={isUserManagementScreenOpen}
          onClose={() => setIsUserManagementScreenOpen(false)}
        />
      </div>
    </AvatarContextProvider>
  )
}

function ExpensePageHeader(props: {
  expense: Expense
  members?: UserProfile[]
  items?: ExpenseItem[]
}) {
  const { expense, members, items } = props
  const hasTip = !!expense.tip
  const hasTax = !!expense.tax
  const hasSubtotal = (expense.subtotal && expense.subtotal !== expense.total) || hasTip || hasTax
  const tipPercent = ((expense.tip ?? 0) / (expense.subtotal ?? 1)) * 100

  return (
    <div className="flex flex-col gap-2 backdrop-blur bg-white/75 pt-2 pb-4 px-4 text-sm">
      <DetailLine name="Paid by" onChange={paidBy => updateExpense(expense.id, { paidBy })}>
        {expense.paidBy ?? "?"}
      </DetailLine>

      {hasSubtotal && (
        <DetailLineNumber
          name="Subtotal"
          onChange={subtotal => updateExpense(expense.id, { subtotal })}
        >
          {toCurrency(expense.subtotal)}
        </DetailLineNumber>
      )}

      {hasTax && (
        <DetailLineNumber name="Tax" onChange={tax => updateExpense(expense.id, { tax })}>
          {toCurrency(expense.tax)}
        </DetailLineNumber>
      )}

      {hasTip && (
        <DetailLineNumber
          name="Tip"
          onChange={tip => updateExpense(expense.id, { tip })}
          value={expense.tip}
        >
          {((expense.tip ?? 0) > 0 ? `${roundToPlaces(tipPercent, 1)}% • ` : "") +
            toCurrency(expense.tip)}
        </DetailLineNumber>
      )}

      <DetailLineNumber
        name="Total"
        className="font-bold text-st-green"
        onChange={total => updateExpense(expense.id, { total })}
      >
        {toCurrency(expense.total)}
      </DetailLineNumber>

      <Balances
        expense={expense}
        items={items ?? []}
        users={members ?? []}
        tipPercent={tipPercent}
      />
    </div>
  )
}

export function DetailLine(props: {
  name: string
  children: ReactNode
  details?: ReactNode
  className?: string
  onChange?: (value: string) => void
  editorType?: "text" | "number"
  value?: string | number
}) {
  const [isEditing, setIsEditing] = useState(false)

  const handleEditClick = () => {
    if (props.onChange) setIsEditing(true)
  }

  const submit = (value: string) => {
    setIsEditing(false)
    props.onChange?.(value)
  }

  const handleEditorBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    submit(e.target.value)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      submit(e.currentTarget.value)
    }
  }

  const value = props.value ?? props.children
  const editorValue = value === "?" ? "" : value?.toString().replace("$", "") ?? ""

  return (
    <div className={classNames("flex gap-4 items-center text-[16px]", props.className)}>
      <div className="flex-nowrap whitespace-nowrap">{props.name}</div>
      <hr className="grow border-black/10" />
      <div
        className="flex items-center gap-0.5 flex-wrap min-w-[15px] min-h-[20px]"
        onClick={handleEditClick}
      >
        {isEditing ? (
          <input
            className="w-[80px] text-right -mr-2 pr-2"
            type={props.editorType ?? "text"}
            // biome-ignore lint/a11y/noAutofocus: <explanation>
            autoFocus
            defaultValue={editorValue}
            onBlur={handleEditorBlur}
            onKeyDown={handleKeyDown}
          />
        ) : (
          props.children
        )}
        {props.details && (
          <div className="text-gray-400 text-xs whitespace-nowrap pl-1">{props.details}</div>
        )}
      </div>
    </div>
  )
}

function DetailLineNumber(
  props: Omit<ComponentProps<typeof DetailLine>, "onChange"> & { onChange: (value: number) => void }
) {
  const handleChange = (value: string) => {
    const parsed = Number.parseFloat(value || "0")
    const number = Number.isNaN(parsed) ? 0 : parsed
    props.onChange(number)
  }

  return <DetailLine {...props} editorType="number" onChange={handleChange} />
}
