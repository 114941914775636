import { auth } from "@/services/firebase"
import { type User, onAuthStateChanged } from "firebase/auth"
import { type ReactNode, createContext, useEffect, useState } from "react"

interface AuthContextValue {
  user: User | null
  isLoading: boolean
}

export const AuthContext = createContext<AuthContextValue | null>(null)

export default function AuthProvider(props: { children: ReactNode }) {
  const [isLoading, setIsLoading] = useState(true)
  const [user, setUser] = useState<User | null>(null)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      setUser(user)
      setIsLoading(false)
    })
    return unsubscribe
  }, [])

  const value = { user, isLoading }

  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
}
