import { useAuth } from "@/services/auth-service"
import { db } from "@/services/firebase.ts"
import type { UpdateDTO, User, UserProfile } from "@/types.ts"
import { useCollection, useDoc } from "@tatsuokaniwa/swr-firestore"
import { arrayUnion, collection, doc, updateDoc } from "firebase/firestore"

const usersCollection = collection(db, "users")

export async function addFriend(friendUserId: string) {
  const currentUserId = useAuth().user?.uid
  if (!currentUserId) {
    throw new Error("User is not logged in")
  }
  await updateUser(currentUserId, { friendIds: arrayUnion(friendUserId) })
}

export async function updateUser(id: string, updates: UpdateDTO<User>) {
  const ref = doc(usersCollection, id)
  await updateDoc(ref, updates)
}

export function useCurrentUser() {
  const currentUserId = useAuth().user?.uid
  return useDoc<User>(currentUserId ? { path: `users/${currentUserId}` } : null)
}

export function useFriends() {
  const currentUser = useCurrentUser()
  const friendIds = currentUser.data?.friendIds ?? []

  return useCollection<UserProfile>(
    friendIds.length
      ? {
          path: "user_profiles",
          where: [["id", "in", friendIds]],
          orderBy: [["firstName", "asc"]]
        }
      : null
  )
}
