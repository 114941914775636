import { Layout } from "@/layout.tsx"
import ExpensePage from "@/pages/expense-page/expense-page.tsx"
import HomePage from "@/pages/home-page.tsx"
import LoginPage from "@/pages/login-page"
import { RouterProvider, createBrowserRouter } from "react-router-dom"

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { index: true, element: <HomePage key="home" /> },
      { path: "/login", element: <LoginPage key="login" /> },
      { path: "/expense/:id", element: <ExpensePage key="expense" /> }
    ]
  }
])

export default function Routes() {
  return <RouterProvider router={router} />
}
