import { AuthContext } from "@/providers/auth-provider"
import { auth } from "@/services/firebase"
import { signInWithEmailAndPassword } from "firebase/auth"
import { useContext } from "react"

export function logIn(email: string, password: string) {
  return signInWithEmailAndPassword(auth, email, password)
}

export function logOut() {
  return auth.signOut()
}

Object.assign(window, { logOut })

export function useAuth() {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider")
  }
  return context
}
